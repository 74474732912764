@font-face {
  font-family: 'OpenSans';
  src: url('OpenSans.woff') format('woff');
  font-weight: thin;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Poppins */
.poppins {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-style: normal;
}

/* Open Sans */
.open-sans {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-style: normal;
}

/* Roboto */
.roboto {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: normal;
}

/* Lato */
.lato {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-style: normal;
}

/* Montserrat */
.montserrat {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-style: normal;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;